<template>
  <div
    v-if="activediscounts && !disabledcart"
    class="cart-discount-form field has-addons border-bottom-ccc"
  >
    <input
      v-model="code"
      :placeholder="$t('cart.promoCode')"
      class="control input"
      @keyup.enter="addDiscount"
    >
    <div class="discount-button control">
      <button
        class="btn btn-primary"
        @click="addDiscount"
      >
        {{ $t('cart.applyDiscount') }}
      </button>
    </div>

    <p
      v-if="message != null"
      class="notice"
    >
      {{ message }}
      <span
        class="close"
        @click="message = null"
      />
    </p>
  </div>
</template>

<script>
import Api from './cart_api'
import {mapActions, mapMutations} from 'vuex'

export default {

  data() {
    return {
      code: null,
      message: null
    }
  },

  computed: {
    activediscounts(){
      return this.$store.state.order.cart.active_discounts
    },
    disabledcart(){
      return this.$store.state.order.order.disabled_cart
    }
  },



  methods: {
    ...mapActions('order', ['getBackendOrder']),
    ...mapMutations('order', ['updateLoading']),
    addDiscount() {
      this.updateLoading(true)
      Api.addDiscount(JSON.stringify({ discount_code: this.code })).then(response => {
        this.getBackendOrder()
        this.message = response.message
        this.updateLoading(false)
      })
    }
  }
}
</script>
